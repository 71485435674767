export const landingPagePlansMessages = {
    'landingPage.plans.title': 'Nossos Planos',

    'landingPage.plans.player.title': 'Para Jogador',
    'landingPage.plans.player.feature1': 'Acesse as oportunidades do mercado.',
    'landingPage.plans.player.feature2': 'Envie seu currículo diretamente para as vagas criadas por clubes e agentes.',
    'landingPage.plans.player.feature3': 'Seja visto por agentes e clubes do mundo inteiro.',
    'landingPage.plans.player.feature4': 'Aumente suas chances de conseguir um bom contrato.',

    'landingPage.plans.player.text1': 'Você sabia que atualmente 94% dos jogadores brasileiros terminam o ano sem um clube?',

    'landingPage.plans.player.text2':
        'Talvez essa até seja a sua realidade. Você termina um campeonato estadual e vive aquela instabilidade, aquela incerteza, sem saber se o seu contrato será renovado, sem saber se terá um clube nos próximos meses, ou a incerteza naquela troca de categoria se continuará no clube, sabendo que tem as obrigações de casa para cumprir, contas para pagar.',

    'landingPage.plans.player.text3':
        'É justamente por isso que a maioria dos jogadores abandonam seu sonho de atuar profissionalmente. Pode ser que você nunca saiba se o seu material chegará nas mãos de um grande clube, de um empresário influente e todos os anos são as mesmas incertezas, o mesmo sufoco.',

    'landingPage.plans.player.text4':
        'Pensando em solucionar esse problema, nasceu a Transferfut, uma plataforma que conecta jogadores a agentes e clubes.',

    'landingPage.plans.player.text5': 'É uma forma de você expor seus materiais, e ter acesso as propostas do mundo inteiro!',

    'landingPage.plans.player.text6': 'Você pode começar ainda hoje GRATUITAMENTE.',

    'landingPage.plans.player.text7':
        'Clique no botão abaixo, faça seu cadastro sem custo algum e conheça as oportunidades que hoje temos disponíveis!',

    'landingPage.plans.clubs.title': 'Para Clube',
    'landingPage.plans.clubs.feature1': 'Cadastre os pedidos que você tem e receba apenas jogadores com as características que você procura.',
    'landingPage.plans.clubs.feature2': 'Envie os seus jogadores para os pedidos de outros clubes, aumentando a chance de negociá-los.',
    'landingPage.plans.clubs.feature3': 'Veja os jogadores que estão disponíveis para empréstimo em outros clubes.',
    'landingPage.plans.clubs.feature4': 'Disponibilize os seus jogadores que estão disponíveis para empréstimo para que outros clubes tenham acesso, agilizando a negociação.',
    'landingPage.plans.clubs.feature5': 'Tenha acesso ao perfil completo dos atletas com as suas informações mais relevantes.',

    'landingPage.plans.clubs.text1':
        'A maioria dos clubes de futebol vem passando pelo mesmo dilema. Todo começo de campeonato é aquela correria atrás de jogadores!',
    'landingPage.plans.clubs.text2':
        'Liga para um clube, liga para um agente, sempre atrás de jogadores livres, para empréstimo ou venda.',
    'landingPage.plans.clubs.text3':
        'Sem mencionar que muitas vezes, o clube fica com jogadores em seu elenco que não fazem parte dos planos do time, mas simplesmente por não aparecer nenhuma oferta, nenhuma negociação, é necessário mantê-los no plantel.',
    'landingPage.plans.clubs.text4':
        'Bem, e se existisse uma forma de anunciar todos os seus jogadores e também ver os jogadores disponíveis no mercado?! Tanto os que estão sem clube, quanto aqueles que estão disponíveis para empréstimo ou venda?',
    'landingPage.plans.clubs.text5':
        'E se existisse uma forma de fechar essas parcerias e contratações de um jeito muito mais simples, prático e rápido?',
    'landingPage.plans.clubs.text6': 'A Transferfut é uma plataforma que nasceu exatamente com esse objetivo!',
    'landingPage.plans.clubs.text7':
        'Criamos um ambiente online onde os clubes e agentes poderão divulgar seus jogadores, conhecer novos atletas, fazer propostas e fechar negociações.',
    'landingPage.plans.clubs.text8': 'É basicamente um comércio eletrônico de jogadores, onde conecta-se clubes, agentes e atletas!',
    'landingPage.plans.clubs.text9':
        'Você pode começar ainda hoje e GRATUITAMENTE! Basta clicar no botão abaixo e conhecer os jogadores disponíveis, analisar materiais e também divulgar aqueles atletas que estão para negócio. Não perca!',

    'landingPage.plans.agents.title': 'Para Agente',
    'landingPage.plans.agents.feature1': 'Acesse os pedidos dos clubes e envie seus jogadores diretamente para eles.',
    'landingPage.plans.agents.feature2': 'Cadastre os pedidos que você tem e receba apenas jogadores com as características que você procura.',
    'landingPage.plans.agents.feature3': 'Envie seus atletas para os pedidos dos agentes e faça novas parcerias.',
    'landingPage.plans.agents.feature4': 'Tenha seu perfil com todos os seus jogadores cadastrados em nossa plataforma, podendo, assim, receber propostas.',
    'landingPage.plans.agents.feature5': 'Envie propostas para todos os agentes e clubes cadastrados em nossa plataforma.',

    'landingPage.plans.agents.text1':
        'É realmente frustrante você receber um bom pedido de um clube, mas não encontrar o jogador para essa oportunidade.',
    'landingPage.plans.agents.text2':
        'Ou ao contrário, ter bons jogadores em seu portfólio mas não encontrar clubes que buscam aquele jogador específico.',
    'landingPage.plans.agents.text3':
        'Isso é cada vez mais comum entre os agentes. Ter apenas uma das partes da negociação e não conseguir encontrar a outra.',
    'landingPage.plans.agents.text4': 'Mas imagine: te ajudaria se existisse uma plataforma online de comércio de jogadores?',
    'landingPage.plans.agents.text5':
        'Onde os clubes e agentes divulgassem suas vagas, detalhassem o tipo de jogador que estão buscando, e para que também os agentes e clubes pudessem divulgar os materiais dos seus jogadores disponíveis com o propósito de fazer sempre novas parcerias e gerar novas negociações.',
    'landingPage.plans.agents.text6': 'Seria muito mais fácil encontrar time para seus jogadores, correto?',
    'landingPage.plans.agents.text7': 'E também seria muito mais fácil encontrar jogadores, novos talentos para seus pedidos!',
    'landingPage.plans.agents.text8': 'Foi pensando em facilitar o comércio de jogadores que nasceu a Transferfut.',
    'landingPage.plans.agents.text9':
        'Uma plataforma 100% online onde você poderá se cadastrar e encontrar tudo que estiver aberto para negociação no mundo futebolístico.',
    'landingPage.plans.agents.text10':
        'Você pode começar GRATUITAMENTE, clique no botão abaixo, e faça o seu cadastro e comece a negociar!',

}
